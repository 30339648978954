import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = ({ id }) => {
  axiosPrivate(API.DeleteUser(id), {
    method: "DELETE",
  });
};

export const useDeleteUser = (config) => useMutation({ mutationFn, config });
