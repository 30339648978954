import { useQuery } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

async function getUsers(params) {
  return axiosPrivate
    .get(API.ListUsers, {
      method: "GET",
      params,
    })
    .then((res) => res.data?.users);
}

const useListUsers = (params, config = {}) => {
  const query = useQuery({
    queryKey: ["users", params],
    queryFn: () => getUsers(params),
    ...config,
  });
  const refetchUsers = () => {
    query.refetch();
  };
  return { ...query, refetchUsers };
};

export { useListUsers };
