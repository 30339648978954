// @ts-check

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { all_routes } from "../../Router/all_routes";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { LOGO } from "../../core/images";
import { clearAccessToken } from "../../lib/auth-storage";
import { useAuth } from "../../api/users/use-authenticate";

const Header = () => {
  const route = all_routes;

  const [toggle, SetToggle] = useState(false);

  const loggingOutModalRef = useRef(null);
  const { currentUser } = useAuth();

  const signOut = () => {
    clearAccessToken();
    navigate(all_routes.signin);
  };

  const navigate = useNavigate();

  const isElementVisible = (element) => {
    return element.offsetWidth > 0 || element.offsetHeight > 0;
  };

  const slideDownSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "block";
      }
    }
  };

  const slideUpSubmenu = () => {
    const subdropPlusUl = document.getElementsByClassName("subdrop");
    for (let i = 0; i < subdropPlusUl.length; i++) {
      const submenu = subdropPlusUl[i].nextElementSibling;
      if (submenu && submenu.tagName.toLowerCase() === "ul") {
        submenu.style.display = "none";
      }
    }
  };

  useEffect(() => {
    const handleMouseover = (e) => {
      e.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn");

      if (
        body.classList.contains("mini-sidebar") &&
        isElementVisible(toggleBtn)
      ) {
        const target = e.target.closest(".sidebar, .header-left");

        if (target) {
          body.classList.add("expand-menu");
          slideDownSubmenu();
        } else {
          body.classList.remove("expand-menu");
          slideUpSubmenu();
        }

        e.preventDefault();
      }
    };

    document.addEventListener("mouseover", handleMouseover);

    return () => {
      document.removeEventListener("mouseover", handleMouseover);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
    SetToggle((current) => !current);
  };
  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const sidebarOverlay = () => {
    document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
    document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
    document?.querySelector("html")?.classList?.toggle("menu-opened");
  };

  let pathname = location.pathname;

  const exclusionArray = [
    "/reactjs/template/dream-pos/index-three",
    "/reactjs/template/dream-pos/index-one",
  ];
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return "";
  }

  return (
    <>
      <div className="header">
        {/* Logo */}
        <div
          className={`header-left ${toggle ? "" : "active"}`}
          onMouseLeave={expandMenu}
          onMouseOver={expandMenuOpen}
        >
          <Link to="/dashboard" className="logo logo-normal">
            <ImageWithBasePath src={LOGO} alt="img" full={true} />
          </Link>

          <Link
            id="toggle_btn"
            to="#"
            style={{
              display: pathname.includes("tasks")
                ? "none"
                : pathname.includes("compose")
                ? "none"
                : "",
            }}
            onClick={handlesidebar}
          >
            <FeatherIcon icon="chevrons-left" className="feather-16" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#"
          onClick={sidebarOverlay}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        {/* Header Menu */}
        <ul className="nav user-menu">
          {/* Search */}
          <li className="nav-item nav-searchinputs"></li>
          {/* /Search */}
          {/* Select Store */}
          {/* /Select Store */}
          {/* Flag */}
          {/* /Flag */}
          {/* Notifications */}
          <li className="nav-item dropdown has-arrow main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link userset"
              data-bs-toggle="dropdown"
            >
              <span className="user-info">
                <span className="user-detail">
                  <span className="user-name">
                    {
                      <h5>
                        {/* {capitalizeFirstChar( */}
                        {currentUser?.fullName || currentUser?.username}
                        {/* )} */}
                      </h5>
                    }
                  </span>
                  <span className="user-role">
                    <h5>{currentUser?.role}</h5>
                  </span>
                </span>
              </span>
            </Link>
            <div className="dropdown-menu menu-drop-user">
              <div className="profilename">
                <div className="profileset">
                  <span className="user-img">
                    {/* <ImageWithBasePath src={LOGO_b_1} alt="img" /> */}
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <h6>
                      {/* {capitalizeFirstChar( */}
                      {currentUser?.fullName || currentUser?.username}
                      {/* )} */}
                    </h6>
                    <h5>{currentUser?.role}</h5>
                  </div>
                </div>
                <hr className="m-0" />
                {/* <Link className="dropdown-item" to={route.route}>
                  <i className="me-2" data-feather="user" /> My Profile
                </Link> */}
                {(currentUser?.role === "admin" ||
                  currentUser?.role === "superAmsAdmin" ||
                  currentUser?.role === "superAdmin") && (
                  <Link className="dropdown-item" to={route.productlist}>
                    <i className="me-2" data-feather="settings" />
                    Settings
                  </Link>
                )}
                <hr className="m-0" />
                <button
                  className="dropdown-item logout pb-0"
                  onClick={() => signOut()}
                >
                  {/* <ImageWithBasePath
                    src="assets/img/icons/log-out.svg"
                    alt="img"
                    className="me-2"
                  /> */}
                  Logout
                </button>
              </div>
            </div>
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="user-profile">
              Settings
            </Link>
            <button className="dropdown-item" onClick={() => signOut()}>
              Logout
            </button>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {/* Loging out with data warning-alert-modal */}
      <button
        ref={loggingOutModalRef}
        type="button"
        className="btn btn-warning mt-1 me-1"
        data-bs-toggle="modal"
        data-bs-target="#warning-alert-modal"
      />
      <div
        id="warning-alert-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body p-4">
              <div className="text-center">
                <i className="dripicons-warning h1 text-warning" />
                <h4 className="mt-2">Missing Checkout!</h4>
                <p className="mt-3">
                  Your sale isn't complete yet. Are you sure you want to logout?
                </p>
                <div
                  className="
                d-flex justify-content-between mt-4
                "
                >
                  <button
                    type="button"
                    className="btn btn-warning my-2"
                    data-bs-dismiss="modal"
                  >
                    Go Back
                  </button>
                  <button
                    type="button"
                    className="btn btn-success my-2"
                    data-bs-dismiss="modal"
                    onClick={() => signOut()}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
