//@ts-check
import React, { useState } from "react";
import CustomInput from "../../../components/input";
import TextareaField from "../../../components/textarea-field";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Select from "react-select";
import { CarBrandNames } from "../../../json/car-brands";
import { CarModelNames } from "../../../json/car-models";

export default function CommonFormFields({ control, getValues, register }) {
  const [selectedCarBrand, setSelectedCarBrand] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "features",
  });

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="col-lg-6 pe-0">
        <div className="mb-3">
          <Controller
            control={control}
            name="make"
            render={({ field, fieldState }) => (
              <>
                <label className="form-label">Make</label>
                <Select
                  value={
                    CarBrandNames?.find(
                      (brand) => brand.value === field.value
                    ) ?? null
                  }
                  onChange={(e) => {
                    field.onChange(e?.value);
                    setSelectedCarBrand(e?.value);
                  }}
                  options={CarBrandNames}
                  name="make"
                />

                {fieldState.error?.message ? (
                  <p className="error-color ">{fieldState.error?.message}</p>
                ) : null}
              </>
            )}
          />
        </div>
      </div>
      <div className="col-lg-6 pe-0">
        <div className="mb-3">
          <Controller
            name="model"
            control={control}
            render={({ field, fieldState }) => {
              const disabled = !selectedCarBrand && !getValues("make");

              return (
                <>
                  <label className="form-label">Model</label>
                  <Select
                    value={
                      CarModelNames[getValues("make")]?.find(
                        (model) => model.value === field.value
                      ) ?? null
                    }
                    onChange={(e) => field.onChange(e.value)}
                    options={
                      selectedCarBrand ? CarModelNames[selectedCarBrand] : []
                    }
                    name="model"
                    isDisabled={disabled}
                    placeholder={
                      selectedCarBrand ? "Select Model" : "Select Brand first"
                    }
                  />

                  {!disabled && fieldState.error?.message ? (
                    <p className="error-color ">{fieldState.error?.message}</p>
                  ) : null}
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="col-lg-6 pe-0">
        <div className="mb-3">
          <Controller
            control={control}
            name="year"
            render={({ field }) => (
              <CustomInput
                {...field}
                control={control}
                name="year"
                parentClassName="form-addons"
                className="form-control"
                label="Year"
              />
            )}
          />
        </div>
      </div>
      <div className="col-lg-6 pe-0">
        <div className="mb-3">
          <Controller
            control={control}
            name="capacity"
            render={({ field }) => (
              <CustomInput
                {...field}
                control={control}
                name="capacity"
                parentClassName="form-addons"
                className="form-control"
                label="Capacity"
              />
            )}
          />
        </div>
      </div>

      <div className="col-lg-12 pe-0">
        <div className="mb-3">
          <Controller
            control={control}
            name="overview"
            render={({ field }) => (
              <TextareaField
                {...field}
                control={control}
                name="overview"
                parentClassName="form-addons"
                className="form-control"
                label="Overview"
                rows={4}
              />
            )}
          />
        </div>
      </div>

      <div className="col-lg-12 pe-0">
        <div className="mb-3">
          <Controller
            control={control}
            name="extras"
            render={({ field }) => (
              <CustomInput
                {...field}
                control={control}
                name="extras"
                parentClassName="form-addons"
                className="form-control"
                label="Extras"
              />
            )}
          />
        </div>
      </div>

      <div className="col-lg-12 pe-0">
        <div className="mb-3">
          {fields.map((field, index) => (
            <div>
              <label htmlFor="features" className="form-label">
                Feature {index + 1}
              </label>
              <div className="d-flex align-items-center mb-3">
                <input
                  key={field.id} // important to include key with field's id
                  {...register(`features.${index}.value`)}
                  className="form-control me-2"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => remove(index)}
                  disabled={fields.length === 1}
                >
                  Remove
                </button>
              </div>
              {errors?.features?.[index]?.value && (
                <p className="error-color">
                  {errors?.features?.[index]?.value?.message}
                </p>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => append({ value: "" })}
        >
          Add Feature
        </button>
      </div>
    </>
  );
}
