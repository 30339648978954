import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
      <p>
        {`Copyright ©${new Date().getFullYear()} `}
        <Link
          to="https://www.advanced-meta.com/"
          className="text-blue"
          target="_blank"
        >
          Advanced Meta Solutions
        </Link>
        {`. All rights reserved`}
      </p>
    </div>
  );
}

export default Footer;
