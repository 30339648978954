// @ts-check
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { apiValidations } from "../../../../lib/apiValidation";
import CustomInput from "../../../components/input";
import { useUpdatePassword } from "../../../../api/profile/use-edit-password";

export default function ChangePassword() {
  const closeModalRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(apiValidations.ChangePassword),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const mutation = useUpdatePassword();

  const onSubmitAdd = (data) => {
    mutation.mutate(
      {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
      {
        onSuccess: (res) => {
          console.log(res);
          closeModalRef?.current?.click();
        },
        onError: (res) => {
          setErrorMessage(res.response.data.message);
          console.log(res.response.data.message);
        },
      }
    );
  };

  return (
    <div className="modal fade" id="edit-profile">
      <div className="modal-dialog modal-dialog-centered custom-modal-two">
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                <div className="page-title">
                  <h4>Change password</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <form onSubmit={handleSubmit(onSubmitAdd)}>
                  <div className="row">
                    <div className="col-lg-12 pe-0">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="currentPassword"
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              control={control}
                              name={"currentPassword"}
                              type="password"
                              className="form-control"
                              parentClassName={"form-addons"}
                              label={"Current Password"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 pe-0">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="newPassword"
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              control={control}
                              name={"newPassword"}
                              type="password"
                              className="form-control"
                              parentClassName={"form-addons"}
                              label={"New Password"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 pe-0">
                      <div className="mb-3">
                        <Controller
                          control={control}
                          name="confirmPassword"
                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              control={control}
                              name={"confirmPassword"}
                              type="password"
                              className="form-control"
                              parentClassName={"form-addons"}
                              label={"Confirm Password"}
                            />
                          )}
                        />
                      </div>
                    </div>

                    {errorMessage && (
                      <p className="text-danger mt-3">{errorMessage}</p>
                    )}
                  </div>

                  <div className="modal-footer-btn">
                    <button
                      type="button"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      ref={closeModalRef}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
