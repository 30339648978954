// @ts-check
import React, { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import Breadcrumbs from "../../../core/breadcrumbs";
import { useListRequests } from "../../../api/modified/requests/use-list-requests";
import AcceptRequestModal from "../../../core/modals/admin/orders/acceptRequestModal";
import clsx from "clsx";
import { useEditRequest } from "../../../api/modified/requests/use-edit-request";

const Orders = () => {
  const [editingRequest, setEditingRequest] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const { data: requests, refetchRequests, isFetching } = useListRequests();

  const filteredRequests = requests?.filter((request) => {
    return searchQuery
      ? request.customer?.fullName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      : true;
  });

  const editOrderMutation = useEditRequest();

  const MySweetAlert = withReactContent(Swal);

  const showCancelRecordConfirmationAlert = (record) => {
    MySweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, cancel it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        editOrderMutation.mutate(
          { ...record, status: "cancelled" },
          {
            onSuccess: () => {
              refetchRequests();
              MySweetAlert.fire({
                title: "Cancelled!",
                text: "The request has been cancelled.",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            },
            onError: () =>
              MySweetAlert.fire({
                title: "Failed Cancelling!",
                text: "Try again later.",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
              }),
          }
        );
      } else {
        MySweetAlert.close();
      }
    });
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "user",
      render: (text, record) => (
        <span>{record.customer?.fullName || text}</span>
      ),
    },
    {
      title: "Google Maps Drive Link",
      dataIndex: "locations",
      render: (text, record) => (
        <span>
          {" "}
          {record?.googleMapUrl ? (
            <a
              className="text-sm text-info"
              href={record?.googleMapUrl}
              target="_blank"
              rel="noreferrer"
            >
              View the ride on Map
            </a>
          ) : (
            text
          )}
        </span>
      ),
    },
    {
      title: "Date And Time",
      dataIndex: "pickupTime",
      render: (text, record) => (
        <span>{new Date(record.pickupTime).toLocaleString() || text}</span>
      ),
    },
    {
      title: "Car",
      dataIndex: "car",
      render: (text, record) => (
        <span>{`${record.car?.make} ${record?.car.model}` || text}</span>
      ),
    },
    {
      title: "Passengers",
      dataIndex: "passengers",
      render: (text, record) => <span>{record.passengers || text}</span>,
    },
    {
      title: "Children",
      dataIndex: "childrens",
      render: (text, record) => <span>{record.childrens || text}</span>,
    },
    {
      title: "Bags",
      dataIndex: "bags",
      render: (text, record) => <span>{record.bags || text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span
          className={clsx(
            "fw-bold text-capitalize",
            record.status === "new" && "text-success",
            record.status === "pending" && "text-secondary",
            record.status == "approved" && "text-info",
            (record.status == "cancelled" || record.status == "rejected") &&
              "text-danger"
          )}
        >
          {record.status || text}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <td className="action-table-data align-items-center d-flex flex-column">
          {record.totalAmount && <p>${record.totalAmount}</p>}
          {record.status !== "approved" &&
            record.status !== "rejected" &&
            record.status !== "cancelled" && (
              <div className="edit-delete-action gap-2">
                {record.status === "pending" && (
                  <button
                    className="btn btn-sm btn-outline-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#accept-unit"
                    onClick={() => setEditingRequest(record)}
                  >
                    Change
                  </button>
                )}

                {record.status === "new" && (
                  <button
                    className="btn btn-sm btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#accept-unit"
                    onClick={() => setEditingRequest(record)}
                  >
                    Accept
                  </button>
                )}

                {(record.status === "new" || record.status === "pending") && (
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => showCancelRecordConfirmationAlert(record)}
                  >
                    Cancel
                  </button>
                )}
              </div>
            )}
        </td>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle="Bookings List"
            subtitle={"Manage Your Bookings"}
            refresh={refetchRequests}
            refreshing={isFetching}
          />
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredRequests}
                  disableRowSelection={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AcceptRequestModal
        editingOrder={editingRequest}
        refetchOrder={refetchRequests}
      />
    </div>
  );
};

export default Orders;
