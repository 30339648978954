const HistoryItem = (props) => {
  return (
    <section className="border rounded p-2">
      <div className="row gap-5 gap-lg-0">
        <article className="col-12 col-md-4 col-lg-2">
          <div className="d-flex gap-3">
            <div>
              <span>Date And Time</span>
              <h5>{new Date(props.date).toLocaleString()}</h5>
            </div>
            <div
              className="d-none d-lg-block col"
              style={{
                "border-left": "1px solid #DBE0E6",
                height: "70px",
              }}
            ></div>
          </div>
        </article>
        <article
          className="col-12 col-md-4 col-lg-2"
          style={{ maxWidth: "240px" }}
        >
          <span>Pickup Location</span>
          <h5>{props.pickup}</h5>
        </article>
        <article
          className="col-12 col-md-4 col-lg-2"
          style={{ maxWidth: "240px" }}
        >
          <span>Drop-off Location</span>
          <h5>{props.dropoff}</h5>
        </article>
        <article className="col-12 col-md-4 col-lg-2">
          <span>Vehicle</span>
          <h5>{props.vehicle}</h5>
        </article>
        <article className="col-12 col-md-4 col-lg-1">
          <span>Bags</span>
          <h5>{props.bags}</h5>
        </article>
        <article className="col-12 col-md-4 col-lg-1">
          <span>Passengers</span>
          <h5>{props.passengers}</h5>
        </article>
        <article className="col-12 col-md-4 col-lg-1">
          <span>Children</span>
          <h5>{props.children}</h5>
        </article>
        <article className="d-flex gap-2 col-12 col-md-4 col-lg-1">
          <div
            className="d-none d-lg-block col"
            style={{
              "border-left": "1px solid #DBE0E6",
              height: "70px",
            }}
          ></div>
          <div className="d-flex flex-column w-full">
            {props.totalAmount && (
              <p className="fw-bold text-primary fs-5">${props.totalAmount}</p>
            )}
            <span>{props.status}</span>
          </div>
        </article>
      </div>
    </section>
  );
};

export default HistoryItem;
