const getResponseErrorMsg = (error) => {
  if (error.response) {
    if (error.response.data.message) return error.response.data.message;

    if (error.response.data.errors) return error.response.data.errors[0].msg;
  }

  return "Server error";
};

export { getResponseErrorMsg };
