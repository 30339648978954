// @ts-check
import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = (body) => {
  const mutatedBody = {
    ...body,
    carId: body.car._id,
    location: body.locations[0],
    destination: body.locations[1],
  };

  return axiosPrivate(API.EditRequest(body._id), {
    method: "PUT",
    data: mutatedBody,
  });
};

export const useEditRequest = (config) => useMutation({ mutationFn, config });
