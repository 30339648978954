//@ts-check
import React from "react";
import { Route } from "react-router-dom";

const routes = all_routes;

import SigninThree from "../feature-module/pages/login/signinThree";

import { all_routes } from "./all_routes";
import Orders from "../feature-module/pages/admin/orders";
import Customers from "../feature-module/pages/admin/customers";
import AvailableCars from "../feature-module/pages/admin/available-cars";
import UserManagement from "../feature-module/pages/admin/user-management";
import UserProfile from "../feature-module/pages/admin/user-profile";

export const publicRoutes = [
  {
    id: 0,
    path: "/",
    name: "signin",
    element: <SigninThree />,
    route: Route,
  },
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <SigninThree />,
    route: Route,
  },
];

export const posRoutes = [
  {
    id: 2,
    path: routes.pos,
    name: "",
    element: <Orders />,
    route: Route,
  },
  {
    id: 3,
    path: routes.customers,
    name: "customers",
    element: <Customers />,
    route: Route,
  },
  {
    id: 4,
    path: routes.availableCars,
    name: "available cars",
    element: <AvailableCars />,
    route: Route,
  },
  {
    id: 4,
    path: routes.users,
    name: "user management",
    element: <UserManagement />,
    route: Route,
  },
  {
    id: 5,
    path: routes.userProfile,
    name: "user profile",
    element: <UserProfile />,
    route: Route,
  },
];
