//@ts-check
import React from "react";
import * as Icon from "react-feather";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsGear } from "react-icons/bs";
import { AiOutlineCar } from "react-icons/ai";

export const SidebarDataAdmin = [
  {
    submenuItems: [
      {
        label: "Bookings",
        link: "",
        icon: <IoDocumentTextOutline />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Customers",
        link: "customers",
        icon: <Icon.User />,
        showSubRoute: false,
        submenu: false,
      },

      {
        label: "Available Cars",
        link: "available-cars",
        icon: <AiOutlineCar />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "User Management",
        link: "user-management",
        icon: <Icon.UserCheck />,
        showSubRoute: false,
        submenu: false,
      },
      {
        label: "Settings",
        link: "/user-profile",
        icon: <BsGear />,
        showSubRoute: false,
        submenu: false,
      },
    ],
  },
];
