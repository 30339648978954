// @ts-check
import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.ToggleCarActiveStatus(body?.id), {
    method: "PUT",
    data: body,
  });
};

export const useToggleCarActiveStatus = (config) => {
  return useMutation({ mutationFn, config });
};
