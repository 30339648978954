//@ts-check

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../lib/apiValidation";
import CustomInput from "../../../core/components/input";
import Footer from "../../../core/components/Footer";
import { useLogin } from "../../../api/use-login";

const SigninThree = () => {
  const loginMutation = useLogin();

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(apiValidations.Login),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    loginMutation.mutate({
      username: data.username?.toString().toLowerCase(),
      password: data.password,
    });
  };

  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper login-new">
          <div className="container ">
            <div className="login-content user-login">
              <form className="w-50" onSubmit={handleSubmit(onSubmit)}>
                <div className="login-userset">
                  <div className="login-userheading">
                    <h3>Sign In</h3>
                  </div>
                  <div className="form-login">
                    <Controller
                      control={control}
                      name="username"
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          control={control}
                          name={"username"}
                          type="text"
                          className="form-control"
                          parentClassName={"form-addons"}
                          label={"Username"}
                        />
                      )}
                    />
                  </div>
                  <div className="form-login">
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => (
                        <CustomInput
                          {...field}
                          control={control}
                          name={"password"}
                          type="password"
                          className="pass-input"
                          label={"Password"}
                          parentClassName="pass-group"
                        />
                      )}
                    />
                  </div>

                  {loginMutation.error ? (
                    <p className="error-color m-0">
                      {loginMutation.error.response.data.message}
                    </p>
                  ) : null}

                  <div className="form-login">
                    <button className="btn btn-login" type="submit">
                      {loginMutation.isPending ? "Signing In..." : "Sign In"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninThree;
