// @ts-check
import React from "react";
import { useController } from "react-hook-form";
import ImageWithBasePath from "../img/imagewithbasebath";
import clsx from "clsx";

const CustomInput = ({
  control,
  rightImage = "",
  name,
  parentClassName,
  label,
  ...props
}) => {
  const { fieldState } = useController({ name, control });

  const errorMessage = fieldState.error?.message;

  const isPassword = props.type === "password";

  //switch hide password
  const togglePassword = (e) => {
    const input = e.target.previousElementSibling;
    if (input.type === "password") {
      input.type = "text";
      e.target.classList.remove("fa-eye-slash");
      e.target.classList.add("fa-eye");
    } else {
      input.type = "password";
      e.target.classList.remove("fa-eye");
      e.target.classList.add("fa-eye-slash");
    }
  };

  return (
    <>
      <label className="form-label">{label}</label>
      <div className={clsx(parentClassName)} style={{ position: "relative" }}>
        <input {...props} />
        {isPassword && (
          <span
            onClick={togglePassword}
            className="fas toggle-password fa-eye-slash"
            style={{
              position: "absolute",
              right: "0.7rem",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
        )}
        {rightImage && <ImageWithBasePath src={rightImage} alt="img" />}
      </div>

      <div>
        {errorMessage ? <p className="error-color ">{errorMessage}</p> : null}
      </div>
    </>
  );
};

export default CustomInput;
