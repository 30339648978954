// @ts-check
import React, { useEffect, useRef } from "react";
import { getResponseErrorMsg } from "../../../../lib/getResponseErrorMsg";
import { useAddUser } from "../../../../api/users/use-add-user";
import { useEditUser } from "../../../../api/modified/users/use-edit-user";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../../lib/apiValidation";
import CustomInput from "../../../components/input";
import DropdownField from "../../../components/dropdown-field";
import clsx from "clsx";

const userRoles = [
  {
    label: "Super Admin",
    value: "superAdmin",
  },
  { label: "Admin", value: "admin" },
];

const UserModal = ({ editingUser, refetchUsers, clearSelectedUser }) => {
  const addUserRef = useRef();
  const editUserRef = useRef();

  return (
    <>
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Add User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <AddUserModalBody
                    refetchUsers={refetchUsers}
                    addUserRef={addUserRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Edit User</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  {editingUser && (
                    <EditUserModalBody
                      refetchUsers={refetchUsers}
                      editUserRef={editUserRef}
                      editingUser={editingUser}
                      clearSelectedUser={clearSelectedUser}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserModal;

function AddUserModalBody({ refetchUsers, addUserRef }) {
  const {
    mutate: addUser,
    isPending: isUserAddedPending,
    isError: isUserAddedError,
    error: addUserError,
    reset: resetAddUserError,
  } = useAddUser();

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(apiValidations.AddUser),
    defaultValues: {
      phoneNumber: "",
      fullName: "",
      username: "",
      role: "",
      password: "",
    },
  });

  const onSubmitAdd = (data) => {
    addUser(data, {
      onSuccess: (resp) => {
        addUserRef?.current?.click();
        reset();
        refetchUsers();
        resetAddUserError();
      },
      onError: (err) => {
        console.error("Error Adding User:", err);
      },
    });
  };

  // reset form on close
  useEffect(() => {
    const addModalElement = document.getElementById("add-units");
    addModalElement?.addEventListener("hidden.bs.modal", reset);

    return () => {
      addModalElement?.removeEventListener("hidden.bs.modal", reset);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitAdd)}>
      <div className="row">
        <div className="col-lg-12 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"username"}
                  type="text"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Username"}
                />
              )}
            />
          </div>
        </div>

        <div className="col-lg-12 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"fullName"}
                  type="text"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Full Name"}
                />
              )}
            />
          </div>
        </div>

        <div className={clsx("pe-0", "col-lg-6")}>
          <div className="mb-3">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"phoneNumber"}
                  type="text"
                  className="form-control"
                  // rightImage={"assets/img/icons/mail.svg"}
                  parentClassName={"form-addons"}
                  label={"Phone Number"}
                />
              )}
            />
          </div>
        </div>

        <div className="col-lg-6 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <DropdownField
                  {...field}
                  control={control}
                  name={"role"}
                  className="form-control"
                  label={"Role"}
                  options={userRoles}
                />
              )}
            />
          </div>
        </div>

        <div className="col-lg-12 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"password"}
                  type="password"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Password"}
                />
              )}
            />
          </div>
        </div>
      </div>
      {isUserAddedError && (
        <div className="alert alert-danger mt-3">
          {getResponseErrorMsg(addUserError)}
        </div>
      )}
      <div className="modal-footer-btn">
        <button
          type="button"
          className="btn btn-cancel me-2"
          data-bs-dismiss="modal"
          ref={addUserRef}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-submit">
          {isUserAddedPending ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
}

function EditUserModalBody({
  refetchUsers,
  editUserRef,
  editingUser,
  clearSelectedUser,
}) {
  const {
    mutate: editUser,
    isPending: isUserEditedPending,
    isError: isUserEditedError,
    error: editUserError,
    reset: resetEditUserError,
  } = useEditUser();

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(apiValidations.EditUser),
    defaultValues: editingUser && {
      phoneNumber: editingUser.phoneNumber,
      fullName: editingUser.fullName,
      username: editingUser.username,
      role: editingUser.role,
    },
  });

  const onSubmitEdit = (e) => {
    e.preventDefault();

    handleSubmit((data) => {
      editUser(
        { ...data, _id: editingUser?._id },
        {
          onSuccess: (resp) => {
            editUserRef?.current?.click();
            reset();
            refetchUsers();
            resetEditUserError();
          },
          onError: (e) => {
            console.log({ error: e });
          },
        }
      );
    })();
  };

  useEffect(() => {
    const addModalElement = document.getElementById("edit-units");
    addModalElement?.addEventListener("hidden.bs.modal", clearSelectedUser);

    return () => {
      addModalElement?.removeEventListener(
        "hidden.bs.modal",
        clearSelectedUser
      );
    };
  }, []);

  return (
    <form onSubmit={onSubmitEdit}>
      <div className="row">
        <div className="col-lg-12 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"username"}
                  type="text"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Username"}
                />
              )}
            />
          </div>
        </div>

        <div className="col-lg-12 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"fullName"}
                  type="text"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Full Name"}
                />
              )}
            />
          </div>
        </div>

        <div className={clsx("pe-0", "col-lg-6")}>
          <div className="mb-3">
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  control={control}
                  name={"phoneNumber"}
                  type="text"
                  className="form-control"
                  parentClassName={"form-addons"}
                  label={"Phone Number"}
                />
              )}
            />
          </div>
        </div>

        <div className="col-lg-6 pe-0">
          <div className="mb-3">
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <DropdownField
                  {...field}
                  control={control}
                  name={"role"}
                  className="form-control"
                  label={"Role"}
                  options={userRoles}
                />
              )}
            />
          </div>
        </div>
      </div>
      {isUserEditedError && (
        <div className="alert alert-danger mt-3" role="alert">
          {getResponseErrorMsg(editUserError)}
        </div>
      )}
      <div className="modal-footer-btn">
        <button
          type="button"
          className="btn btn-cancel me-2"
          data-bs-dismiss="modal"
          ref={editUserRef}
        >
          Cancel
        </button>
        <button type="submit" className="btn btn-submit">
          {isUserEditedPending ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </form>
  );
}
