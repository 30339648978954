//@ts-check
import React from "react";
import { useController } from "react-hook-form";

const CustomImageUploader = ({
  control,
  name,
  parentClassName,
  label = "",
  disabled = false,
  multiple = false,
}) => {
  const { fieldState, field } = useController({ name, control });
  const errorMessage = fieldState.error?.message;
  const images = field.value || [];
  const setImages = (newImages) => {
    field.onChange(newImages);
  };

  // Function to convert file to base64 string
  const handleFileChange = (e) => {
    const files = e.target.files ? Array.from(e.target.files) : [];

    if (multiple) {
      // dont add image twice
      const newImages = files.filter(
        (file) => !images.find((image) => image.name === file.name)
      );
      setImages([...images, ...newImages]);
    } else setImages(files);
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div
        className={`${parentClassName} w-100 `}
        style={{ marginRight: "10px" }}
      >
        <label className="form-label">{label}</label>
        <div className=" ">
          <div style={{ position: "relative" }}>
            <input
              disabled={disabled}
              id={name}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }} // Hide the input
              accept="image/*" // Ensure only images can be selected
              multiple={multiple}
            />
            <label
              htmlFor={name}
              className=" w-100 form-control cursor"
              style={{
                borderStyle: "dashed",
                borderWidth: "2px",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="h-full w-full  d-flex align-items-center  ">
                  <div>
                    <span className={" fa fa-upload text-primary"}></span>
                  </div>

                  <p className={"ms-2" + (disabled ? " text-muted " : "")}>
                    {images?.length > 0
                      ? multiple
                        ? "Upload more images"
                        : "Change image"
                      : multiple
                      ? "Upload images"
                      : "Upload image"}
                  </p>
                </div>
              </div>
            </label>
          </div>
        </div>

        {errorMessage && <p className="error-color">{errorMessage}</p>}

        {/* Optionally render the image preview */}
      </div>
      <div className="d-flex gap-2 flex-wrap">
        {images.map((file, index) => (
          <div key={index} className="position-relative">
            <label htmlFor="file">
              <img
                src={URL.createObjectURL(file)}
                alt="uploaded"
                className="object-cover rounded-1"
                style={{
                  height: "70px",
                  width: "70px",
                  objectFit: "cover",
                }}
              />
            </label>

            {/* Delete image button */}
            <button
              type="button"
              className="btn btn-danger btn-sm rounded-circle position-absolute top-0 end-0"
              onClick={() => {
                setImages(images.filter((_, i) => i !== index));
                field.onChange(images.filter((_, i) => i !== index));
              }}
            >
              <span className="fa fa-trash-o">x</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomImageUploader;
