// @ts-check

import React, { useState } from "react";
import { Table } from "antd";
import { bgRowColor } from "../../lib/tableFunctions";

const Datatable = ({ columns, dataSource, disableRowSelection }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = disableRowSelection
    ? null
    : {
        selectedRowKeys,
        onChange: onSelectChange,
      };

  return (
    <Table
      rowClassName={bgRowColor}
      className="table datanew dataTable no-footer"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record._id}
    />
  );
};

export default Datatable;
