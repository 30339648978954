// @ts-check
import React, { useState } from "react";
import { Link } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Edit, Trash2 } from "react-feather";
import Table from "../../../core/pagination/datatable";
import Breadcrumbs from "../../../core/breadcrumbs";
import { useListUsers } from "../../../api/modified/users/use-list-users";
import { useDeleteUser } from "../../../api/modified/users/use-delete-user";
import UserModal from "../../../core/modals/admin/user-management/user-modal";

const UserManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { data: users, refetchUsers, isFetching } = useListUsers();

  const filteredUsers = users?.filter((user) =>
    searchQuery
      ? user.fullName
          .toLocaleLowerCase()
          .includes(searchQuery.trim().toLocaleLowerCase()) ||
        user.username?.trim().toLocaleLowerCase()?.includes(searchQuery)
      : true
  );

  const [selectedUser, setSelectedUser] = useState(null);

  const { mutate: deleteUser } = useDeleteUser();

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (customer_id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(
          { id: customer_id },
          {
            onSuccess: () => {
              refetchUsers();
              MySwal.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            },
            onError: () =>
              MySwal.fire({
                title: "Failed Deleting!",
                text: "Try again later.",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
              }),
          }
        );
      } else {
        MySwal.close();
      }
    });
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      render: (text, record) => <span>{record.fullName || text}</span>,
    },
    {
      title: "Username",
      dataIndex: "username",
      render: (text, record) => <span>{record.username || text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text, record) => <span>{record.role || text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      render: (text, record) => <span>{record.phoneNumber || text}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => setSelectedUser(record)}
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(record._id)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </td>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle={"Users List"}
            addButton={"Add User"}
            refresh={refetchUsers}
            refreshing={isFetching}
          />
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredUsers}
                  disableRowSelection={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserModal
        editingUser={selectedUser}
        refetchUsers={refetchUsers}
        clearSelectedUser={() => setSelectedUser(null)}
      />
    </div>
  );
};

export default UserManagement;
