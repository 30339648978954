import { useMutation } from "@tanstack/react-query";
import { API } from "../../lib/api";
import { axiosPrivate } from "../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.AddUsers, {
    method: "POST",
    data: body,
  });
};

export const useAddUser = (config) => useMutation({ mutationFn, config });
