//@ts-check
import React from "react";
import { useToggleCarActiveStatus } from "../../../api/modified/cars/use-toggle-car-active-status";

export default function ChangeVisibilityStatus({ record, refresh }) {
  const { isActive } = record;

  const mutation = useToggleCarActiveStatus();

  return (
    <div className="status-toggle modal-status d-flex align-items-center ps-5">
      <input
        type="checkbox"
        id={record._id}
        className="check"
        value={isActive}
        onChange={(e) => {
          mutation.mutate(
            {
              id: record._id,
              isActive: e.target.checked,
            },
            { onSuccess: () => refresh() }
          );
        }}
        checked={isActive}
      />
      <label htmlFor={record._id} className="checktoggle" />

      {mutation.isPending && (
        <div className="spinner-border spinner-border-sm ms-1" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </div>
  );
}
