//@ts-check
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAddCar } from "../../../../api/modified/cars/use-add-car";
import CustomImageUploader from "../../../components/CustomImageUploader";

import { getResponseErrorMsg } from "../../../../lib/getResponseErrorMsg";
import CommonFormFields from "./common-form-fields";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../../lib/apiValidation";

export default function AddCarForm({ addCarRef, refetchCars }) {
  const methods = useForm({
    resolver: zodResolver(apiValidations.CarForm),
    defaultValues: {
      make: "",
      model: "",
      year: "",
      capacity: "",
      extras: "",
      overview: "",
      features: [{ value: "" }],
      mainImage: [],
      images: [],
    },
  });
  const { control, handleSubmit, register, reset, getValues } = methods;

  const {
    mutate: addCar,
    isPending: isCarAddedPending,
    isError: isCarAddedError,
    error: addCarError,
    reset: resetAddCarError,
  } = useAddCar();

  const onSubmitAdd = (e) => {
    e.preventDefault();

    handleSubmit((data) => {
      const {
        mainImage,
        images,
        make,
        model,
        year,
        capacity,
        extras,
        overview,
        features: featuresArr,
      } = data;

      const formData = new FormData();

      const features = featuresArr
        ?.map((feature) => feature.value)
        .filter(Boolean);

      formData.append("make", make);
      formData.append("model", model);
      formData.append("year", year);
      formData.append("capacity", capacity);
      formData.append("extras", extras);
      formData.append("overview", overview);
      formData.append("features", JSON.stringify(features));

      const allImages = [];
      if (mainImage?.length > 0) {
        allImages.push(...mainImage);
        allImages.push(...images);
      } else {
        allImages.push(...images);
      }

      allImages.forEach((image) => {
        formData.append("images", image);
      });

      addCar(formData, {
        onSuccess: () => {
          addCarRef?.current.click();
          reset();
          refetchCars();
          resetAddCarError();
        },
        onError: (err) => {
          console.error("Error Adding Car: ", err);
        },
      });
    })();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmitAdd}>
        <div className="row">
          <div className="col-lg-12 pe-0">
            <div className="mb-3">
              <Controller
                control={control}
                name="mainImage"
                render={() => (
                  <CustomImageUploader
                    control={control}
                    label="Main Image"
                    name="mainImage"
                    parentClassName="form-addons"
                  />
                )}
              />
            </div>
          </div>

          <div className="col-lg-12 pe-0">
            <div className="mb-3">
              <Controller
                control={control}
                name="images"
                render={() => (
                  <CustomImageUploader
                    control={control}
                    label="Images"
                    name="images"
                    parentClassName="form-addons"
                    multiple
                  />
                )}
              />
            </div>
          </div>

          <CommonFormFields
            control={control}
            getValues={getValues}
            register={register}
          />
        </div>
        {isCarAddedError && (
          <div className="alert alert-danger mt-3">
            {getResponseErrorMsg(addCarError)}
          </div>
        )}
        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            data-bs-dismiss="modal"
            ref={addCarRef}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-submit"
            disabled={isCarAddedPending}
          >
            {isCarAddedPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
