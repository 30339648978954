// @ts-check
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.EditProfile, {
    method: "PUT",
    data: body,
  });
};

export const useEditProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["auth"] }),
  });
};
