// @ts-check
import React from "react";
import { useController } from "react-hook-form";

const DropdownField = ({ control, name, label, options, ...props }) => {
  const { fieldState } = useController({ name, control });

  const errorMessage = fieldState.error?.message;

  return (
    <>
      <label className="form-label">{label}</label>
      <div>
        <select {...props}>
          <option value="">Select</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div>
        {errorMessage ? <p className="error-color ">{errorMessage}</p> : null}
      </div>
    </>
  );
};

export default DropdownField;
