//@ts-check
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API } from "../lib/api";
import { axiosPrivate } from "../lib/privateAxios";
import { setAccessToken } from "../lib/auth-storage";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../Router/all_routes";

const mutationFn = (body) => {
  return axiosPrivate(API.Login, {
    method: "POST",
    data: body,
  });
};

export const useLogin = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: ({ data }) => {
      setAccessToken(data.token);
      queryClient.invalidateQueries({ queryKey: ["auth"] });
      navigate(all_routes.pos);
    },
  });
};
