//@ts-check
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useEditCar } from "../../../../api/modified/cars/use-edit-car";
import CustomImageUploader from "../../../components/CustomImageUploader";
import { getResponseErrorMsg } from "../../../../lib/getResponseErrorMsg";
import { API_URL } from "../../../../lib/api";
import CommonFormFields from "./common-form-fields";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../../lib/apiValidation";

export default function EditCarForm({ editingCar, refetchCars, editCarRef }) {
  const methods = useForm({
    resolver: zodResolver(apiValidations.CarForm),
    defaultValues: editingCar && {
      make: editingCar.make,
      model: editingCar.model,
      year: editingCar.year,
      capacity: editingCar.capacity,
      extras: editingCar.extras,
      overview: editingCar.overview,
      features: editingCar.features.map((feature) => ({
        value: feature,
      })),
      editingMainImage: [],
      editingImages: [],
    },
  });
  const { control, handleSubmit, reset, register, setValue, getValues } =
    methods;

  const [uploadedImages, setUploadedImages] = useState(
    editingCar?.images || []
  );
  const [deletedImages, setDeletedImages] = useState([]);

  const {
    mutate: editCar,
    isError: isEditCarError,
    isPending: isEditCarPending,
    error: editCarError,
    reset: resetEditCarError,
  } = useEditCar();

  const onSubmitEdit = (e) => {
    e.preventDefault();
    handleSubmit((data) => {
      const {
        editingMainImage,
        editingImages,
        make,
        model,
        year,
        capacity,
        extras,
        overview,
        features: featuresArr,
      } = data;

      const formData = new FormData();

      const features = featuresArr
        ?.map((feature) => feature.value)
        .filter(Boolean);

      formData.append("make", make);
      formData.append("model", model);
      formData.append("year", year);
      formData.append("capacity", capacity);
      formData.append("extras", extras);
      formData.append("overview", overview);
      formData.append("features", JSON.stringify(features));

      const allImages = [];
      if (editingMainImage && editingMainImage?.length > 0) {
        allImages.push(...editingMainImage);
        allImages.push(...editingImages);
      } else if (editingImages) {
        allImages.push(...editingImages);
      }

      allImages.forEach((image) => {
        formData.append("images", image);
      });

      if (deletedImages.length > 0) {
        formData.append("deletedImages", JSON.stringify(deletedImages));
      }

      editCar(
        { id: editingCar._id, formData },
        {
          onSuccess: (resp) => {
            editCarRef?.current.click();
            reset();
            refetchCars();
            resetEditCarError();
          },
          onError: (err) => {
            console.log("Error Editing Car: ", err);
          },
        }
      );
    })();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmitEdit}>
        <div className="row">
          <div className="col-lg-12 pe-0">
            <div className="mb-3">
              <Controller
                control={control}
                name="editingMainImage"
                render={() => (
                  <CustomImageUploader
                    control={control}
                    label="Main Image"
                    name="editingMainImage"
                    parentClassName="form-addons"
                  />
                )}
              />
            </div>
          </div>

          <div className="col-lg-12 pe-0">
            <div className="mb-3">
              <Controller
                control={control}
                name="editingImages"
                render={() => (
                  <CustomImageUploader
                    control={control}
                    label="Images"
                    name="editingImages"
                    parentClassName="form-addons"
                    multiple
                  />
                )}
              />
            </div>
          </div>

          <div className="col-lg-12 pe-0">
            <div className="mb-4">
              <p>Current Images</p>
              <div className="d-flex flex-wrap gap-2">
                {uploadedImages?.map((image) => (
                  <div
                    className="position-relative"
                    style={{ width: "100px", height: "70px" }}
                    key={image}
                  >
                    <img
                      src={image}
                      alt="uploaded"
                      className="w-100 h-100 object-fit-cover"
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-circle position-absolute top-0 end-0"
                      onClick={() => {
                        setDeletedImages((prev) => [...prev, image]);
                        setUploadedImages((prev) =>
                          prev.filter((img) => img !== image)
                        );
                      }}
                    >
                      <span className="fa fa-trash-o">x</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <CommonFormFields
            control={control}
            setValue={setValue}
            getValues={getValues}
            register={register}
          />
        </div>
        {isEditCarError && (
          <div className="alert alert-danger mt-3">
            {getResponseErrorMsg(editCarError)}
          </div>
        )}
        <div className="modal-footer-btn">
          <button
            type="button"
            className="btn btn-cancel me-2"
            data-bs-dismiss="modal"
            ref={editCarRef}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-submit"
            disabled={isEditCarPending}
          >
            {isEditCarPending ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
