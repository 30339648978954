//@ts-check
import React, { useEffect, useState } from "react";
import HistoryItem from "./historyItem";
import { useListRequests } from "../../../../api/modified/requests/use-list-requests";

const UserModalHistory = ({ id, user, clearSelectedUser }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: requests } = useListRequests({ id });

  const filteredRequests = requests?.filter((request) =>
    searchQuery
      ? request.locations[0]
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        request.locations[1].toLowerCase().includes(searchQuery.toLowerCase())
      : true
  );

  useEffect(() => {
    return () => {
      clearSelectedUser();
    };
  }, []);

  return (
    <div className="modal fade" id="view-units">
      <div
        className="modal-dialog modal-dialog-center custom-modal-two"
        style={{ width: "80%", maxWidth: "80%" }}
      >
        <div className="modal-content">
          <div className="page-wrapper-new p-0">
            <div className="content">
              <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                <div className="page-title">
                  <h4>Customer History</h4>
                </div>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body custom-modal-body">
                <h5>Customer Info</h5>
                <div className="border p-2 d-flex flex-column flex-md-row align-items-md-center justify-content-between rounded">
                  <div className="my-2 my-md-0">
                    <span>Customer Name</span>
                    <h5>{user?.fullName}</h5>
                  </div>
                  <div className="my-2 my-md-0">
                    <span>Phone Number</span>
                    <h5>{user?.phoneNumber}</h5>
                  </div>
                  <div className="my-2 my-md-0">
                    <span>Email</span>
                    <h5>{user?.email}</h5>
                  </div>
                </div>
                <div className="search d-flex align-items-center justify-content-between mt-4">
                  <div className="d-flex flex-column flex-md-row align-items-center gap-4">
                    <h5>Customer History</h5>
                    <div className="search-set">
                      <div className="search-input">
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-control form-control-sm formsearch"
                        />
                        <button className="btn btn-searchset">
                          <i data-feather="search" className="feather-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-column gap-4 mt-4"
                  style={{ width: "100%" }}
                >
                  {filteredRequests?.length > 0 ? (
                    filteredRequests?.map((item, i) => (
                      <HistoryItem
                        status={item.status}
                        bags={item.bags}
                        date={item.pickupTime}
                        pickup={item.locations[0]}
                        dropoff={item.locations[1]}
                        passengers={item.passengers}
                        children={item.childrens}
                        vehicle={`${item.car.make} - ${item.car.model}`}
                        totalAmount={item.totalAmount}
                      />
                    ))
                  ) : (
                    <h5>No Rides</h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModalHistory;
