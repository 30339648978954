// @ts-check
import React from "react";
import { useController } from "react-hook-form";
import clsx from "clsx";

const TextareaField = ({
  control,
  rightImage = "",
  name,
  parentClassName,
  label,
  ...props
}) => {
  const { fieldState } = useController({ name, control });

  const errorMessage = fieldState.error?.message;

  return (
    <>
      <label className="form-label">{label}</label>
      <div className={clsx(parentClassName)}>
        <textarea {...props} />
      </div>

      <div>
        {errorMessage ? <p className="error-color ">{errorMessage}</p> : null}
      </div>
    </>
  );
};

export default TextareaField;
