import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.AddCar, {
    method: "POST",
    data: body,
  });
};

export const useAddCar = (config) => useMutation({ mutationFn, config });
