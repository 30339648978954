//@ts-check

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { posRoutes, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import ThemeSettings from "../InitialPage/themeSettings";
import Loader from "../feature-module/loader/loader";
import { useAuth } from "../api/users/use-authenticate";

const AllRoutes = () => {
  const { currentUser, isLoading } = useAuth();

  const isAmsSuperAdmin = false;

  const Pospages = () => (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <Outlet />
      {isAmsSuperAdmin && <ThemeSettings />}
    </div>
  );

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <Routes>
          {currentUser && (
            <Route path="/" element={<Pospages />}>
              {posRoutes.map((route, id) => (
                <Route path={route.path} element={route.element} key={id} />
              ))}
            </Route>
          )}

          <Route path={"/"}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>

          <Route path={"/*"} element={<Navigate to="/" />} />
        </Routes>
      )}
    </div>
  );
};
export default AllRoutes;
