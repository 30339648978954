export const CarModelNames = {
  Acura: [
    { value: "ILX", label: "ILX" },
    { value: "MDX", label: "MDX" },
    { value: "RDX", label: "RDX" },
    { value: "TLX", label: "TLX" },
    { value: "NSX", label: "NSX" },
    { value: "RLX", label: "RLX" },
  ],
  "Alfa Romeo": [
    { value: "4C", label: "4C" },
    { value: "Giulia", label: "Giulia" },
    { value: "Stelvio", label: "Stelvio" },
    { value: "Spider", label: "Spider" },
  ],
  "Aston Martin": [
    { value: "DB11", label: "DB11" },
    { value: "DBS", label: "DBS" },
    { value: "Vantage", label: "Vantage" },
    { value: "Rapide", label: "Rapide" },
    { value: "Vanquish", label: "Vanquish" },
  ],
  Audi: [
    { value: "A3", label: "A3" },
    { value: "A4", label: "A4" },
    { value: "A5", label: "A5" },
    { value: "A6", label: "A6" },
    { value: "A7", label: "A7" },
    { value: "A8", label: "A8" },
    { value: "Q3", label: "Q3" },
    { value: "Q5", label: "Q5" },
    { value: "Q7", label: "Q7" },
    { value: "Q8", label: "Q8" },
  ],
  BMW: [
    { value: "1 Series", label: "1 Series" },
    { value: "2 Series", label: "2 Series" },
    { value: "3 Series", label: "3 Series" },
    { value: "4 Series", label: "4 Series" },
    { value: "5 Series", label: "5 Series" },
    { value: "6 Series", label: "6 Series" },
    { value: "7 Series", label: "7 Series" },
    { value: "8 Series", label: "8 Series" },
    { value: "X1", label: "X1" },
    { value: "X2", label: "X2" },
    { value: "X3", label: "X3" },
    { value: "X4", label: "X4" },
    { value: "X5", label: "X5" },
    { value: "X6", label: "X6" },
    { value: "X7", label: "X7" },
  ],
  Chevrolet: [
    { value: "Bolt", label: "Bolt" },
    { value: "Camaro", label: "Camaro" },
    { value: "Colorado", label: "Colorado" },
    { value: "Corvette", label: "Corvette" },
    { value: "Equinox", label: "Equinox" },
    { value: "Impala", label: "Impala" },
    { value: "Malibu", label: "Malibu" },
    { value: "Silverado", label: "Silverado" },
    { value: "Suburban", label: "Suburban" },
    { value: "Tahoe", label: "Tahoe" },
    { value: "Traverse", label: "Traverse" },
  ],
  Dodge: [
    { value: "Challenger", label: "Challenger" },
    { value: "Charger", label: "Charger" },
    { value: "Durango", label: "Durango" },
    { value: "Journey", label: "Journey" },
    { value: "Viper", label: "Viper" },
  ],
  Ferrari: [
    { value: "488", label: "488" },
    { value: "812 Superfast", label: "812 Superfast" },
    { value: "California", label: "California" },
    { value: "GTC4Lusso", label: "GTC4Lusso" },
    { value: "Portofino", label: "Portofino" },
  ],
  Ford: [
    { value: "Bronco", label: "Bronco" },
    { value: "Edge", label: "Edge" },
    { value: "Escape", label: "Escape" },
    { value: "Expedition", label: "Expedition" },
    { value: "Explorer", label: "Explorer" },
    { value: "F-150", label: "F-150" },
    { value: "Mustang", label: "Mustang" },
    { value: "Ranger", label: "Ranger" },
    { value: "Transit", label: "Transit" },
  ],
  Honda: [
    { value: "Accord", label: "Accord" },
    { value: "Civic", label: "Civic" },
    { value: "CR-V", label: "CR-V" },
    { value: "Fit", label: "Fit" },
    { value: "HR-V", label: "HR-V" },
    { value: "Insight", label: "Insight" },
    { value: "Odyssey", label: "Odyssey" },
    { value: "Passport", label: "Passport" },
    { value: "Pilot", label: "Pilot" },
    { value: "Ridgeline", label: "Ridgeline" },
  ],
  Hyundai: [
    { value: "Elantra", label: "Elantra" },
    { value: "Kona", label: "Kona" },
    { value: "Palisade", label: "Palisade" },
    { value: "Santa Fe", label: "Santa Fe" },
    { value: "Sonata", label: "Sonata" },
    { value: "Tucson", label: "Tucson" },
    { value: "Veloster", label: "Veloster" },
    { value: "Venue", label: "Venue" },
  ],
  Jaguar: [
    { value: "E-Pace", label: "E-Pace" },
    { value: "F-Pace", label: "F-Pace" },
    { value: "F-Type", label: "F-Type" },
    { value: "I-Pace", label: "I-Pace" },
    { value: "XE", label: "XE" },
    { value: "XF", label: "XF" },
    { value: "XJ", label: "XJ" },
  ],
  Jeep: [
    { value: "Cherokee", label: "Cherokee" },
    { value: "Compass", label: "Compass" },
    { value: "Gladiator", label: "Gladiator" },
    { value: "Grand Cherokee", label: "Grand Cherokee" },
    { value: "Renegade", label: "Renegade" },
    { value: "Wrangler", label: "Wrangler" },
  ],
  Kia: [
    { value: "Forte", label: "Forte" },
    { value: "Niro", label: "Niro" },
    { value: "Optima", label: "Optima" },
    { value: "Rio", label: "Rio" },
    { value: "Sorento", label: "Sorento" },
    { value: "Soul", label: "Soul" },
    { value: "Sportage", label: "Sportage" },
    { value: "Stinger", label: "Stinger" },
  ],
  Lamborghini: [
    { value: "Aventador", label: "Aventador" },
    { value: "Huracan", label: "Huracan" },
    { value: "Urus", label: "Urus" },
  ],
  "Land Rover": [
    { value: "Discovery", label: "Discovery" },
    { value: "Range Rover", label: "Range Rover" },
    { value: "Range Rover Evoque", label: "Range Rover Evoque" },
    { value: "Range Rover Sport", label: "Range Rover Sport" },
    { value: "Range Rover Velar", label: "Range Rover Velar" },
  ],
  Lexus: [
    { value: "ES", label: "ES" },
    { value: "GS", label: "GS" },
    { value: "GX", label: "GX" },
    { value: "IS", label: "IS" },
    { value: "LC", label: "LC" },
    { value: "LS", label: "LS" },
    { value: "LX", label: "LX" },
    { value: "NX", label: "NX" },
    { value: "RC", label: "RC" },
    { value: "RX", label: "RX" },
    { value: "UX", label: "UX" },
  ],
  Mazda: [
    { value: "CX-3", label: "CX-3" },
    { value: "CX-30", label: "CX-30" },
    { value: "CX-5", label: "CX-5" },
    { value: "CX-9", label: "CX-9" },
    { value: "Mazda3", label: "Mazda3" },
    { value: "Mazda6", label: "Mazda6" },
    { value: "MX-5 Miata", label: "MX-5 Miata" },
  ],
  "Mercedes-Benz": [
    { value: "A-Class", label: "A-Class" },
    { value: "C-Class", label: "C-Class" },
    { value: "E-Class", label: "E-Class" },
    { value: "G-Class", label: "G-Class" },
    { value: "GLA", label: "GLA" },
    { value: "GLB", label: "GLB" },
    { value: "GLC", label: "GLC" },
    { value: "GLE", label: "GLE" },
    { value: "GLS", label: "GLS" },
    { value: "S-Class", label: "S-Class" },
    { value: "SL", label: "SL" },
    { value: "SLC", label: "SLC" },
  ],
  Mitsubishi: [
    { value: "Eclipse Cross", label: "Eclipse Cross" },
    { value: "Mirage", label: "Mirage" },
    { value: "Outlander", label: "Outlander" },
    { value: "Outlander Sport", label: "Outlander Sport" },
  ],
  Nissan: [
    { value: "Altima", label: "Altima" },
    { value: "Armada", label: "Armada" },
    { value: "Frontier", label: "Frontier" },
    { value: "Kicks", label: "Kicks" },
    { value: "Leaf", label: "Leaf" },
    { value: "Maxima", label: "Maxima" },
    { value: "Murano", label: "Murano" },
    { value: "Pathfinder", label: "Pathfinder" },
    { value: "Rogue", label: "Rogue" },
    { value: "Sentra", label: "Sentra" },
    { value: "Titan", label: "Titan" },
    { value: "Versa", label: "Versa" },
  ],
  Porsche: [
    { value: "718", label: "718" },
    { value: "911", label: "911" },
    { value: "Cayenne", label: "Cayenne" },
    { value: "Macan", label: "Macan" },
    { value: "Panamera", label: "Panamera" },
    { value: "Taycan", label: "Taycan" },
  ],
  Subaru: [
    { value: "Ascent", label: "Ascent" },
    { value: "BRZ", label: "BRZ" },
    { value: "Crosstrek", label: "Crosstrek" },
    { value: "Forester", label: "Forester" },
    { value: "Impreza", label: "Impreza" },
    { value: "Legacy", label: "Legacy" },
    { value: "Outback", label: "Outback" },
    { value: "WRX", label: "WRX" },
  ],
  Tesla: [
    { value: "Model 3", label: "Model 3" },
    { value: "Model S", label: "Model S" },
    { value: "Model X", label: "Model X" },
    { value: "Model Y", label: "Model Y" },
    { value: "Cybertruck", label: "Cybertruck" },
  ],
  Toyota: [
    { value: "4Runner", label: "4Runner" },
    { value: "Avalon", label: "Avalon" },
    { value: "Camry", label: "Camry" },
    { value: "Corolla", label: "Corolla" },
    { value: "Highlander", label: "Highlander" },
    { value: "Land Cruiser", label: "Land Cruiser" },
    { value: "Prius", label: "Prius" },
    { value: "RAV4", label: "RAV4" },
    { value: "Sequoia", label: "Sequoia" },
    { value: "Sienna", label: "Sienna" },
    { value: "Tacoma", label: "Tacoma" },
    { value: "Tundra", label: "Tundra" },
  ],
  Volkswagen: [
    { value: "Atlas", label: "Atlas" },
    { value: "Beetle", label: "Beetle" },
    { value: "Golf", label: "Golf" },
    { value: "Jetta", label: "Jetta" },
    { value: "Passat", label: "Passat" },
    { value: "Tiguan", label: "Tiguan" },
    { value: "Touareg", label: "Touareg" },
  ],
  Volvo: [
    { value: "S60", label: "S60" },
    { value: "S90", label: "S90" },
    { value: "V60", label: "V60" },
    { value: "V90", label: "V90" },
    { value: "XC40", label: "XC40" },
    { value: "XC60", label: "XC60" },
    { value: "XC90", label: "XC90" },
  ],
};
