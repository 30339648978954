import { API } from "../../lib/api";
import { axiosPrivate } from "../../lib/privateAxios";
import { useQuery } from "@tanstack/react-query";

async function getCustomers() {
  return axiosPrivate(API.ListCustomers, {
    method: "GET",
  }).then((res) => res.data.data);
}

const useListCustomers = (config = {}) => {
  const query = useQuery({
    queryKey: ["customers"], // This is your query key
    queryFn: () => getCustomers(), // This is your query function
    ...config, // Spread any additional config options
  });

  const refetchCustomers = () => {
    query.refetch();
  };

  return {
    ...query,
    refetchCustomers,
  };
};

export { useListCustomers };
