//@ts-check
import React, { useEffect, useRef } from "react";
import AddCarForm from "./add-car-form";
import EditCarForm from "./edit-car-form";

const CarsModal = ({ editingCar, refetchCars, clearSelectedCar }) => {
  const addCarRef = useRef();
  const editCarRef = useRef();

  useEffect(() => {
    const editModalElement = document.getElementById("edit-units");
    editModalElement?.addEventListener("hidden.bs.modal", clearSelectedCar);

    return () => {
      editModalElement?.removeEventListener(
        "hidden.bs.modal",
        clearSelectedCar
      );
    };
  }, []);

  return (
    <>
      <div className="modal fade" id="add-units">
        <div className="modal-dialog modal-dialog-center custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Add Vehicle</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body custom-modal-body">
                <AddCarForm addCarRef={addCarRef} refetchCars={refetchCars} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit car modal */}
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-center custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Edit Vehicle</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
              <div className="modal-body custom-modal-body">
                {editingCar && (
                  <EditCarForm
                    editCarRef={editCarRef}
                    editingCar={editingCar}
                    refetchCars={refetchCars}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarsModal;
