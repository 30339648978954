// @ts-check
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { apiValidations } from "../../../../lib/apiValidation";
import CustomInput from "../../../components/input";
import { getResponseErrorMsg } from "../../../../lib/getResponseErrorMsg";
import React, { useEffect, useRef } from "react";
import { useEditRequest } from "../../../../api/modified/requests/use-edit-request";

const AcceptRequestModal = ({ editingOrder, refetchOrder }) => {
  const acceptModalRef = useRef();
  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: zodResolver(apiValidations.acceptOrder),
    defaultValues: {
      totalAmount: 0,
    },
  });

  const {
    mutate: acceptOrder,
    isPending: isAcceptOrderPending,
    isError: isAcceptOrderError,
    error: acceptOrderError,
    reset: resetAcceptOrderError,
  } = useEditRequest();

  const handleAcceptRequest = (e) => {
    e.preventDefault();
    handleSubmit((data) => {
      acceptOrder(
        {
          ...editingOrder,
          status: "pending",
          totalAmount: data.totalAmount,
        },
        {
          onSuccess: (resp) => {
            acceptModalRef?.current?.click();
            reset();
            refetchOrder();
            resetAcceptOrderError();
          },
          onError: (err) => {
            console.log(err);
          },
        }
      );
    })();
  };

  useEffect(() => {
    if (editingOrder) {
      reset({
        totalAmount: editingOrder.totalAmount || 0,
      });
    } else {
      reset();
    }
  }, [editingOrder]);

  const handleModalClose = () => {
    reset();
    resetAcceptOrderError();
  };

  useEffect(() => {
    const modalElement = document.getElementById("accept-unit");
    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  return (
    <>
      <div className="modal fade" id="accept-unit">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Car Order</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleAcceptRequest}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <Controller
                            control={control}
                            name="totalAmount"
                            render={({ field }) => {
                              return (
                                <CustomInput
                                  {...field}
                                  control={control}
                                  name="totalAmount"
                                  type="text"
                                  placeholder="99.99"
                                  className="form-control"
                                  parentClassName={"form-addons"}
                                  label={"Quotation Price"}
                                  onChange={(e) => {
                                    let value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    value = parseFloat(value || 0);
                                    setValue("totalAmount", value);
                                  }}
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {isAcceptOrderError && (
                      <div className="alert alert-danger mt-3">
                        {getResponseErrorMsg(acceptOrderError)}
                      </div>
                    )}
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        ref={acceptModalRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        {isAcceptOrderPending ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptRequestModal;
