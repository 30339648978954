// @ts-check
import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = ({ id, formData }) => {
  return axiosPrivate(API.EditCar(id), {
    method: "PUT",
    data: formData,
  });
};

export const useEditCar = (config) => {
  return useMutation({ mutationFn, config });
};
