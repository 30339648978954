const API = {
  // Users
  Authenticate: "/users/authenticate", // GET
  Login: "/users/login", // POST
  ListUsers: "/users", // GET
  AddUsers: "/users/add", // POST
  EditUsers: (id) => `/users/edit/${id}`, // PUT
  DeleteUser: (id) => `/users/${id}`, // DELETE
  ChangeAdminPass: "/users/change-password", // PATCH

  // CARS
  listCars: "/cars",
  AddCar: "/cars/add",
  EditCar: (id) => `/cars/update/${id}`,
  ToggleCarActiveStatus: (id) => `/cars/toggle-active/${id}`,
  DeleteCar: (id) => `/cars/delete/${id}`,

  // Validate Discount
  validateDiscount: "/users/is-admin",

  // Requests
  ListRequests: "/bookings",
  listUserRequests: (id) => `/bookings?customerId=${id}`,
  EditRequest: (id) => `/bookings/update/${id}`,

  // Customers
  ListCustomers: "/customers", // GET
  AddCustomers: "/customers/add", // POST
  EditCustomer: (id) => `/customers/${id}`, // PUT

  // Profile
  EditProfile: "users/edit-profile", // PUT
  UpdatePassword: "/users/change-pass",
};

export { API };

export const API_URL = process.env.REACT_APP_API_URL;
