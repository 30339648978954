export const all_routes = {
  pos: "/",
  customers: "/customers",
  availableCars: "/available-cars",
  users: "/user-management",
  userProfile: "/user-profile",
  signin: "/signin",
  signintwo: "/signin-2",
  signinthree: "/signin-3",
};
