// @ts-check
import React, { useEffect, useRef } from "react";
import { getResponseErrorMsg } from "../../../../lib/getResponseErrorMsg";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../../lib/apiValidation";
import CustomInput from "../../../components/input";
import clsx from "clsx";
import { useEditCustomer } from "../../../../api/customers/use-edit-customer";

const CustomerModal = ({
  editingCustomer,
  refetchCustomers,
  clearSelectedUser,
}) => {
  const editCustomerRef = useRef();

  const {
    mutate: editCustomer,
    isPending: isCustomerEditedPending,
    isError: isCustomerEditedError,
    error: editCustomerError,
    reset: resetEditCustomerError,
  } = useEditCustomer();

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(apiValidations.EditCustomer),
    defaultValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
    },
  });

  useEffect(() => {
    if (editingCustomer) {
      reset({
        fullName: editingCustomer.fullName,
        phoneNumber: editingCustomer.phoneNumber,
        email: editingCustomer.email,
      });
    } else {
      reset();
    }
  }, [editingCustomer]);

  const onModalClose = () => {
    handleModalClose();
    clearSelectedUser?.();
  };

  useEffect(() => {
    const modalElement = document.getElementById("edit-units");
    modalElement?.addEventListener("hidden.bs.modal", onModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", onModalClose);
    };
  }, []);

  const onSubmitEdit = (e) => {
    e.preventDefault();

    handleSubmit((data) => {
      editCustomer(
        { ...data, _id: editingCustomer?._id },
        {
          onSuccess: (resp) => {
            editCustomerRef?.current?.click();
            reset();
            refetchCustomers();
            resetEditCustomerError();
          },
          onError: (e) => {
            console.log({ error: e });
          },
        }
      );
    })();
  };

  const handleModalClose = () => {
    reset();
    resetEditCustomerError();
  };

  return (
    <>
      <div className="modal fade" id="edit-units">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header justify-content-between align-items-center">
                  <div className="page-title">
                    <h4>Edit Customer</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={onSubmitEdit}>
                    <div className="row">
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <Controller
                            control={control}
                            name="fullName"
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                control={control}
                                name={"fullName"}
                                type="text"
                                className="form-control"
                                parentClassName={"form-addons"}
                                label={"Full Name"}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className={clsx("pe-0", "col-lg-12")}>
                        <div className="mb-3">
                          <Controller
                            control={control}
                            name="phoneNumber"
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                control={control}
                                name={"phoneNumber"}
                                type="text"
                                className="form-control"
                                // rightImage={"assets/img/icons/mail.svg"}
                                parentClassName={"form-addons"}
                                label={"Phone Number"}
                              />
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <Controller
                            control={control}
                            name="email"
                            render={({ field }) => (
                              <CustomInput
                                {...field}
                                control={control}
                                name={"email"}
                                type="email"
                                className="form-control"
                                parentClassName={"form-addons"}
                                label={"Email"}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {isCustomerEditedError && (
                      <div className="alert alert-danger mt-3" role="alert">
                        {getResponseErrorMsg(editCustomerError)}
                      </div>
                    )}
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        ref={editCustomerRef}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        {isCustomerEditedPending ? "Saving..." : "Save Changes"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerModal;
