//@ts-check
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Edit } from "feather-icons-react/build/IconComponents";
import Table from "../../../core/pagination/datatable";
import Breadcrumbs from "../../../core/breadcrumbs";
import { useListCustomers } from "../../../api/customers/use-list-customers";
import CustomerModalHistory from "../../../core/modals/admin/customers/CustomerModalHistory";
import CustomerModal from "../../../core/modals/admin/customers/edit-customer-modal";

const Customers = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: customers, refetchCustomers, isFetching } = useListCustomers();

  const filteredCustomers = customers?.filter((customer) =>
    searchQuery
      ? customer.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase())
      : true
  );

  const [historyId, setHistoryId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      render: (text, record) => <span>{record.fullName || text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      render: (text, record) => <span>{record.phoneNumber || text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => <span>{record.email || text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <button
              className="outline-none py-1 px-2"
              data-bs-toggle="modal"
              data-bs-target="#view-units"
              onClick={() => {
                setHistoryId(record._id);
                setSelectedUser(record);
              }}
              style={{
                cursor: "pointer",
                borderRadius: "8px",
                border: "1px solid black",
                marginRight: "0.5rem",
                background: "transparent",
              }}
            >
              View History
            </button>
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => setSelectedUser(record)}
            >
              <Edit className="feather-edit" />
            </Link>
          </div>
        </td>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle="Customers List"
            refresh={refetchCustomers}
            refreshing={isFetching}
          />
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  dataSource={filteredCustomers}
                  columns={columns}
                  disableRowSelection={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerModalHistory
        id={historyId}
        user={selectedUser}
        clearSelectedUser={() => setSelectedUser(null)}
      />
      <CustomerModal
        refetchCustomers={refetchCustomers}
        editingCustomer={selectedUser}
        clearSelectedUser={() => setSelectedUser(null)}
      />
    </div>
  );
};

export default Customers;
