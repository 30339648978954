import { useQuery } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

async function getCars(params) {
  return axiosPrivate(API.listCars, {
    method: "GET",
    params,
  }).then((res) => res.data.data);
}

const useListCars = (params, config = {}) => {
  const query = useQuery({
    queryKey: ["cars", params],
    queryFn: () => getCars(params),
    ...config,
  });
  const refetchCars = () => {
    query.refetch();
  };
  return {
    ...query,
    refetchCars,
  };
};

export { useListCars };
