import { API } from "../../lib/api";
import { axiosPrivate } from "../../lib/privateAxios";
import { useMutation } from "@tanstack/react-query";

const mutationFn = (body) => {
  return axiosPrivate(API.UpdatePassword, {
    method: "PUT",
    data: body,
  });
};

export const useUpdatePassword = (config) =>
  useMutation({ mutationFn, config });
