import { useMutation } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.EditUsers(body._id), {
    method: "PUT",
    data: body,
  });
};

export const useEditUser = (config) => useMutation({ mutationFn, config });
