//@ts-check
import { API } from "../../lib/api";
import { setAccessToken } from "../../lib/auth-storage";
import { axiosPrivate } from "../../lib/privateAxios";
import { useQuery } from "@tanstack/react-query";

async function getUser() {
  return axiosPrivate(API.Authenticate, {
    method: "GET",
  }).then((res) => res.data);
}

const useAuth = () => {
  const query = useQuery({
    queryKey: ["auth"],
    queryFn: getUser,
    refetchOnMount: false,
    retry: false,
    refetchOnWindowFocus: false,
  });

  if (query?.data?.token) {
    setAccessToken(query.data.token);
  }

  return {
    currentUser: query?.data?.user,
    ...query,
  };
};

export { useAuth };
