//@ts-check
import { useQuery } from "@tanstack/react-query";
import { API } from "../../../lib/api";
import { axiosPrivate } from "../../../lib/privateAxios";

async function getRequests(params) {
  return axiosPrivate({
    url: params?.id ? API.listUserRequests(params?.id) : API.ListRequests,
    method: "GET",
    params,
  }).then((res) => res.data.data);
}
const useListRequests = (params, config = {}) => {
  const query = useQuery({
    queryKey: params?.id ? ["requests", params?.id] : ["requests"],
    queryFn: () => getRequests(params),
    ...config,
  });

  const refetchRequests = () => {
    query.refetch();
  };

  return {
    ...query,
    refetchRequests,
  };
};

export { useListRequests };
