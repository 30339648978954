// @ts-check
import React, { useState } from "react";
import { Controller, set, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { apiValidations } from "../../../lib/apiValidation";
import CustomInput from "../../../core/components/input";
import ChangePassword from "../../../core/modals/admin/user-profile/change-password";
import { useEditProfile } from "../../../api/modified/users/use-edit-profile";
import { useAuth } from "../../../api/users/use-authenticate";

export default function UserProfile() {
  const { currentUser } = useAuth();

  const [successMessage, setSuccessMessage] = useState("");

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(apiValidations.EditProfile),
    defaultValues: currentUser && {
      fullName: currentUser?.fullName,
      userName: currentUser?.username,
      phoneNumber: currentUser?.phoneNumber,
    },
  });

  const mutation = useEditProfile();

  const onSubmitAdd = (data) => {
    mutation.mutate(data, {
      onSuccess: (res) => {
        setSuccessMessage("Profile updated successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      },
      onError: () => {
        console.log("error");
      },
    });
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <form onSubmit={handleSubmit(onSubmitAdd)}>
            <div className="row">
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="fullName"
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        control={control}
                        name={"fullName"}
                        type="text"
                        className="form-control"
                        parentClassName={"form-addons"}
                        label={"Full Name"}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="userName"
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        control={control}
                        name={"userName"}
                        type="text"
                        className="form-control"
                        parentClassName={"form-addons"}
                        label={"Username"}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-12 pe-0">
                <div className="mb-3">
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <CustomInput
                        {...field}
                        control={control}
                        name={"phoneNumber"}
                        type="text"
                        className="form-control"
                        parentClassName={"form-addons"}
                        label={"Phone Number"}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-cancel me-2"
              data-bs-toggle="modal"
              data-bs-target="#edit-profile"
            >
              Change password
            </button>

            {successMessage && (
              <p className="text-success mt-3">{successMessage}</p>
            )}
            <div className="modal-footer-btn">
              <button type="submit" className="btn btn-submit">
                {mutation.isPending ? "Updating..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <ChangePassword />
    </>
  );
}
