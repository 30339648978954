const bgRowColor = (record, index) => {
  // Define an array of colors
  const colors = ["bg-gray-100  ", "bg-white"];

  // Apply color based on the index of the row
  return colors[index % colors.length];
};

function getValueByKey(item, key) {
  const keys = key.split("."); // Split the key by period to get an array of nested keys

  let value = item;
  for (const nestedKey of keys) {
    // Check if the current value is an object and has the nested key
    if (value && typeof value === "object" && nestedKey in value) {
      value = value[nestedKey];
    } else {
      // If the nested key is not found, set value to undefined and break the loop
      value = undefined;
      break;
    }
  }

  return value;
}

function searchList(query, list, keys, setList) {
  const searchTerm = query.toLowerCase();
  let str = "";
  let temp = list.filter((item) => {
    str = "";
    keys.forEach((key) => {
      str += getValueByKey(item, key) + " ";
    });

    return str.toLowerCase().includes(searchTerm);
  });

  setList(temp);
}

export { bgRowColor, searchList };
