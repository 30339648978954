//@ts-check
import React, { useState } from "react";
import { Link } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import Breadcrumbs from "../../../core/breadcrumbs";
import { useListCars } from "../../../api/modified/cars/use-list-cars";
import CarsModal from "../../../core/modals/admin/available-cars/carsModal";
import { useDeleteCar } from "../../../api/modified/cars/use-delete-car";
import { Edit, Trash2 } from "react-feather";
import ChangeVisibilityStatus from "../../../core/components/cars/change-visibility-status";

const AvailableCars = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isActiveStatus, setIsActiveStatus] = useState(false);

  const {
    data: cars,
    refetchCars,
    isFetching,
  } = useListCars({
    active: isActiveStatus ? true : undefined,
  });
  const filteredCars = cars?.filter((car) =>
    searchQuery
      ? car.make
          .toLocaleLowerCase()
          .includes(searchQuery.toLocaleLowerCase()) ||
        car.model.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
      : true
  );

  const { mutate: deleteCar } = useDeleteCar();

  const [selectedCar, setSelectedCar] = useState(null);

  const MySweetAlert = withReactContent(Swal);
  const showConfirmationAlert = (car_id) => {
    MySweetAlert.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCar(
          { id: car_id },
          {
            onSuccess: () => {
              refetchCars();
              MySweetAlert.fire({
                title: "Deleted!",
                text: "Your file has been deleted.",
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            },
            onError: (err) =>
              MySweetAlert.fire({
                title: "Failed Deleting!",
                text: err.response?.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
              }),
          }
        );
      } else {
        MySweetAlert.close();
      }
    });
  };

  const columns = [
    {
      title: "Make & Model",
      dataIndex: "make",
      sorter: (a, b) => a.make.localeCompare(b.make),
      render: (text, record) => <span>{`${record.make} ${record.model}`}</span>,
    },
    {
      title: "Year",
      dataIndex: "year",
      sorter: (a, b) => a.year - b.year,
      render: (text, record) => <span>{record.year || text}</span>,
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      sorter: (a, b) => a.capacity - b.capacity,
      render: (text, record) => <span>{record.capacity || text}</span>,
    },
    {
      title: "Extras",
      dataIndex: "extras",
      render: (text, record) => <span>{record.extras || text}</span>,
    },
    {
      title: "Features",
      dataIndex: "features",
      render: (text, record) => <span>{record.features.join(", ")}</span>,
    },
    {
      title: "Availability Status",
      dataIndex: "_id",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (text, record) => (
        <ChangeVisibilityStatus record={record} refresh={refetchCars} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
              onClick={() => setSelectedCar(record)}
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(record._id)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </td>
      ),
    },
  ];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Breadcrumbs
            maintitle={"Available Cars"}
            refresh={refetchCars}
            addButton={"Add Car"}
            refreshing={isFetching}
          />
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="d-flex">
                  {/* search bar */}
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control form-control-sm formsearch"
                      />
                      <button className="btn btn-searchset">
                        <i data-feather="search" className="feather-search" />
                      </button>
                    </div>
                  </div>

                  {/* status filter */}
                  <div className="status-toggle modal-status d-flex flex-column align-items-center">
                    <span className="status-label">Show active only</span>
                    <input
                      type="checkbox"
                      id="status-filter"
                      className="check"
                      onChange={(e) => setIsActiveStatus(e.target.checked)}
                      checked={isActiveStatus}
                    />
                    <label htmlFor="status-filter" className="checktoggle" />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={filteredCars}
                  disableRowSelection={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarsModal
        editingCar={selectedCar}
        refetchCars={refetchCars}
        clearSelectedCar={() => setSelectedCar(null)}
      />
    </div>
  );
};

export default AvailableCars;
