import { useMutation } from "@tanstack/react-query";
import { API } from "../../lib/api";
import { axiosPrivate } from "../../lib/privateAxios";

const mutationFn = (body) => {
  return axiosPrivate(API.EditCustomer(body._id), {
    method: "PUT",
    data: body,
  });
};

export const useEditCustomer = (config) => useMutation({ mutationFn, config });
